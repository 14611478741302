import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { RefAttributes } from 'react';
import { JSX } from 'react/jsx-runtime';

// 定义你的 SVG 图标
const IconSvg = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.75 21.5H7.75C2.32 21.5 0 19.18 0 13.75V7.75C0 2.32 2.32 0 7.75 0H13.75C19.18 0 21.5 2.32 21.5 7.75V13.75C21.5 19.18 19.18 21.5 13.75 21.5ZM7.75 1.5C3.14 1.5 1.5 3.14 1.5 7.75V13.75C1.5 18.36 3.14 20 7.75 20H13.75C18.36 20 20 18.36 20 13.75V7.75C20 3.14 18.36 1.5 13.75 1.5H7.75Z" fill="white" />
        <path d="M10.7506 11.8297C10.6206 11.8297 10.4906 11.7997 10.3706 11.7297L5.07061 8.6697C4.71061 8.4597 4.59059 7.9997 4.80059 7.6497C5.01059 7.2897 5.47061 7.1697 5.82061 7.3797L10.7406 10.2297L15.6306 7.3997C15.9906 7.1897 16.4506 7.3197 16.6506 7.6697C16.8506 8.0197 16.7306 8.4897 16.3806 8.6897L11.1206 11.7297C11.0106 11.7897 10.8806 11.8297 10.7506 11.8297Z" fill="white" />
        <path d="M10.75 17.2701C10.34 17.2701 10 16.9301 10 16.5201V11.0801C10 10.6701 10.34 10.3301 10.75 10.3301C11.16 10.3301 11.5 10.6701 11.5 11.0801V16.5201C11.5 16.9301 11.16 17.2701 10.75 17.2701Z" fill="white" />
        <path d="M10.7502 17.4998C10.1702 17.4998 9.6003 17.3698 9.1403 17.1198L5.94025 15.3398C4.98025 14.8098 4.24023 13.5398 4.24023 12.4398V9.0498C4.24023 7.95981 4.99025 6.6898 5.94025 6.1498L9.1403 4.3698C10.0603 3.8598 11.4402 3.8598 12.3602 4.3698L15.5602 6.1498C16.5202 6.6798 17.2603 7.94981 17.2603 9.0498V12.4398C17.2603 13.5298 16.5102 14.7998 15.5602 15.3398L12.3602 17.1198C11.9002 17.3798 11.3302 17.4998 10.7502 17.4998ZM10.7502 5.4998C10.4202 5.4998 10.1002 5.5598 9.8702 5.6898L6.67026 7.4698C6.18026 7.7398 5.74023 8.4998 5.74023 9.0498V12.4398C5.74023 12.9998 6.18026 13.7498 6.67026 14.0198L9.8702 15.7998C10.3302 16.0598 11.1702 16.0598 11.6302 15.7998L14.8302 14.0198C15.3202 13.7498 15.7603 12.9898 15.7603 12.4398V9.0498C15.7603 8.48981 15.3202 7.7398 14.8302 7.4698L11.6302 5.6898C11.4002 5.5598 11.0802 5.4998 10.7502 5.4998Z" fill="white" />
    </svg>
);

// 创建自定义的 Icon 组件
const Transaction = (props: JSX.IntrinsicAttributes & Omit<IconComponentProps, "ref"> & RefAttributes<HTMLSpanElement>) => <Icon component={IconSvg} {...props} />;

export default Transaction;
